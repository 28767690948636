import Logo from "../components/Logo";
import pp from "../assets/team/PP.jpg";
import ap from "../assets/team/AP.jpg";
import vk from "../assets/team/VK.jpg";
import waikato from "../assets/images/ourwork/Waikato.png";
import azurmendi from "../assets/images/ourwork/Azurmendi.jpg";
import fukuoka from "../assets/images/ourwork/Fukuoka.jpg";
import krabi from "../assets/images/ourwork/KRABI.png";
import lebernardi from "../assets/images/ourwork/Lebernardin.png";
import neoliss from "../assets/images/ourwork/neoliss.jpg";
import abudhabi from "../assets/images/ourwork/abudhabi.avif";
import Robuchon from "../assets/images/ourwork/Robuchon.png";
import aimaha from "../assets/images/loveData/ai-maha.jpg";
import aiNaseem from "../assets/images/loveData/Al Naseem.jpg";
import atlantis from "../assets/images/loveData/Atlantis.jpg";
import bulgari from "../assets/images/loveData/Bulgari.jpg";
import oberoi from "../assets/images/loveData/Oberoi.jpg";
import palazzo from "../assets/images/loveData/Palazzo.jpg";
import qasr from "../assets/images/loveData/Qasr.jpg";
import zaya from "../assets/images/loveData/Zaya.jpg";
import athens from "../assets/images/ourwork/Athens.png";
import Kyoto from "../assets/images/ourwork/Kyoto.png";
import Botswana from "../assets/images/ourwork/Botswana N.png";
import burjAiArab from "../assets/images/ourwork/BurjAlArab.avif";
import neckerIsland from "../assets/images/ourwork/Necker Island.png";
import Explora from "../assets/images/ourwork/Explora.png";
import BalletOf from "../assets/images/ourwork/Ballet of.png";
import Penguin from "../assets/images/ourwork/Penguin Parade.png";
import blackSun from "../assets/images/ourwork/Black Sun.png";
import biomul from "../assets/images/ourwork/Bioluminescent.png";
import contact from "../assets/backgroundImages/contact.jpg";
import leisure from "../assets/backgroundImages/leisure.jpg";
import about from "../assets/backgroundImages/about.jpg";
import home from "../assets/backgroundImages/home.avif";
import travelTrends from "../assets/backgroundImages/travelTrends.jpg";
import starling from "../assets/images/ourwork/startling.jpeg";
import international from "../assets/images/ourwork/internationalDark.jpeg";

export const navItems = [
  {
    name: "About",
    path: "/about",
    backgroundImage: about,
  },
  {
    name: "Leisure Travel",
    path: "/leisure",
    backgroundImage: leisure,
  },
  {
    name: <Logo />,
    path: "/",
    backgroundImage: home,
  },
  {
    name: "Travel Trends",
    path: "/travel-trends",
    backgroundImage: travelTrends,
  },
  {
    name: "Contact",
    path: "/contact",
    backgroundImage: contact,
  },
];

export const loveData = [
  {
    title: "Al Maha, Dubai Desert Resort",
    img: aimaha,
    description: `A hidden gem in the desert, Al Maha offers a rare opportunity to experience the luxury of seclusion with its Bedouin-inspired suites, each with a private pool overlooking the dunes. Guests can enjoy desert safaris, camel rides, and falconry. It's the perfect mix of adventure and tranquility in a stunning desert setting.`,
  },
  {
    title: "Atlantis The Palm – Dubai",
    img: atlantis,
    description: `Atlantis is more than just a luxury resort—it's a world unto itself. Home to the famous Aquaventure Waterpark, The Lost Chambers Aquarium, and some of Dubai's most iconic dining venues (like Nobu), it offers a combination of thrills and relaxation. The resort's underwater suites, with panoramic views of marine life, are a standout feature.`,
  },
  {
    title: "Zaya Nurai Island – Abu DhabiNurai",
    img: zaya,
    description: `Accessible by a private boat ride, Zaya Nurai Island is an exclusive and secluded island retreat. The resort offers private villas and beachfront estates with infinity pools, and each villa has direct beach access. It’s known for its intimate feel and tranquil environment, ideal for couples and families looking for privacy and relaxation in a tropical paradise.`,
  },
  {
    title: "Bulgari Resort Dubai – Dubai",
    img: bulgari,
    description: `Located on a private island, this Bulgari-branded resort combines Italian design with Arabian influences. The stunning marina, private beach, world-class dining, and the signature Bulgari Spa make it an ultra-luxurious and highly private retreat. Its sophisticated design and exclusivity set it apart as one of Dubai's top luxury destinations.`,
  },
  {
    title: "The Oberoi Beach Resort, Al Zorah",
    img: oberoi,
    description: `This eco-conscious resort is set in a lush, green mangrove forest and offers an immersive nature experience. The resort’s contemporary design integrates perfectly with its natural surroundings. Guests can enjoy kayaking, stand-up paddleboarding, and bird watching, while the wellness center offers Ayurvedic treatments for rejuvenation.`,
  },
  {
    title: "Qasr Al Sarab Desert Resort by Anantara",
    img: qasr,
    description: `This remote desert resort is a dream for those seeking solitude in one of the world’s most expansive deserts. The resort is built to resemble a royal Arabian palace and offers spectacular views of the dunes. Guests can enjoy camel treks, dune bashing, or simply relax in the opulent rooms with desert views. It’s a unique blend of luxury and adventure.`,
  },
  {
    title: "Palazzo Versace Dubai – Dubai",
    img: palazzo,
    description: `Designed in collaboration with the iconic Italian fashion house, Versace, the hotel is a masterpiece of lavish Italian-style interiors, featuring intricate mosaics, marble floors, and opulent fabrics. It’s a fashion lover’s paradise and offers high-end dining and elegant accommodation with an unmistakably glamorous flair.`,
  },
  {
    title: "Al Naseem – Madinat Jumeirah – Dubai",
    img: aiNaseem,
    description: `Al Naseem, part of the sprawling Madinat Jumeirah Resort, offers a contemporary twist on traditional Arabian design. The resort's distinctive feature is its 2 km of private beaches and direct access to the Madinat's canal waterways. Its location gives guests a sense of being in a traditional Arabian souk while enjoying modern luxury.`,
  },
  // {
  //   title: "Anantara Sir Bani Yas Island Resort – Abu Dhabi",
  //   img: "https://rasktravel.com/data/assets/4e251965a457833d21a14f64b56b49f1.jpg",
  //   description: `Located on an island that was once home to a royal hunting reserve, this resort combines luxury with a wildlife experience. Guests can go on safari through the island’s nature reserve, which is home to free-roaming Arabian oryx and gazelles. The resort offers a range of outdoor adventures and is the perfect blend of relaxation and adventure in a natural setting.`,
  // },
  // {
  //   title: "The St. Regis Saadiyat Island Resort – Abu Dhabi",
  //   img: "https://rasktravel.com/data/assets/4e251965a457833d21a14f64b56b49f1.jpg",
  //   description: `A true beachfront haven, this resort is located on Saadiyat Island, famous for its pristine beaches and proximity to cultural landmarks like the Louvre Abu Dhabi. The resort offers expansive rooms and villas, a fantastic golf course, and luxurious spas. It’s an ideal destination for art and culture lovers, with the island’s growing collection of museums and galleries.`,
  // },
];

export const testimonialData = [
  {
    profession: `Vasil I. & family`,
    feedback: `Traveling with I-SKY was a breeze. They took care of everything, from flights and transfers to excursions and dining reservations. We were able to relax and enjoy our holiday without any worries. Their expertise and dedication to customer satisfaction are truly commendable...`,
  },
  {
    profession: `Joerg H.`,
    feedback: `Our stay at the exclusive resort recommended by I-SKY was pure luxury. The attention to detail and the level of comfort provided were unparalleled. The team at I-SKY ensured that every aspect of our trip was perfect, from the moment we arrived until we departed. We couldn't have asked for a better travel agency.`,
  },
  {
    profession: `Salik Imran & family`,
    feedback: `I've been using I-SKY for all my trips since 2021. A friend recommended them, and I loved how easy they made my first trip to the United States. Since then, I've used their services for trips to Norway, Bali, Thailand, France, Canada, and Denmark. They create a private group with their team to help me choose a destination and organize everything quickly.`,
  },
  {
    profession: `Hazza Mansoory & family`,
    feedback: `I-SKY is the best, especially for my family trips to the United Kingdom and Europe. With 14 members, it's tough to organize everything, but I-SKY makes it seamless. Every trip is planned in advance and organized using a checklist. A team member is always available online in case of any issues. We receive check-ins before every flight and reminders for any extensions. It's like having a private travel desk facility, which we love the most.`,
  },
];

export const partnersData = [
  {
    img: "https://rasktravel.com/data/assets/partners_belmond_bellini.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_aman.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_cheval_blanc.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_desigh_hotels.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_dorchester_diamond_club.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_four_seasons.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_hyatt_prive.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_lhw_vita.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/02a259d214fff84adf707edb3ec165cd.png",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_peninsula_penclub.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_raffles.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_rosewood_elite.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_six_senses.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/partners_slh.jpg",
  },
  {
    img: "https://rasktravel.com/data/assets/98d7a4d05fe1dbcbb47539fe53c116b9.png",
  },
  {
    img: "https://rasktravel.com/data/assets/98c0419f3637b6c5b99e09b2a0421bd1.png",
  },
];

export const teamMembers = [
  {
    id: 1,
    initials: "A.P.",
    name: "Asmita P.",
    jobTitle: "Founder Member & Itinerary Expert",
    location: "Dubai",
    tonguesTied: "English, Hindi",
    familyFunZones:
      "Phuket, Paris, Amsterdam, Greece – family fun, here we come!",
    soloEscapadeHavens: "Italy, Armenia, Bali – just me, myself, and a gelato!",
    bucketListWishes:
      "Iceland, Switzerland – because who wouldn’t want to live like a snow-covered fairytale?",
    travelTip:
      "A little sprinkle of advance planning and booking magic goes a long way!",
    mailto: "mailto:mail@iskytravels.com",
    photo: ap,
  },
  {
    id: 2,
    initials: "P.P.",
    name: "Pawan P.",
    jobTitle: "Founder Member & COO",
    location: "Dubai, United Arab Emirates",
    tonguesTied: "English, Hindi & Arabic",
    familyFunZones: "BALI, Koh Samui, Thailand & the Marvelous Singapore",
    soloEscapadeHavens: "Amsterdam and the Land of the Rising Sun – Japan!",
    bucketListWishes:
      "Sardinia, Italy, and a WILD adventure in the Galapagos, Ecuador!",
    travelTip: "Pack light, because less is definitely more!",
    mailto: "mailto:mail@iskytravels.com",
    photo: pp,
  },
  {
    id: 3,
    initials: "V.K.",
    name: "Vivek K.",
    jobTitle: "Financial Administrator",
    location: "Ontario, Canada",
    tonguesTied: "English & Spanish (¡Hola, amigo!)",
    familyFunZones: "The dazzling streets of New York City",
    soloEscapadeHavens: "The buzzing vibe of London",
    bucketListWishes:
      "Conquer the Great Wall of China, frolic in the Swiss Alps, and mingle with the wildlife in the Galapagos Islands!",
    travelTip: "Knowing a few local phrases helps a lot!",
    mailto: "mailto:mail@iskytravels.com",
    photo: vk,
  },
];

export const galleryImages = [
  {
    src: "https://images.unsplash.com/photo-1512100356356-de1b84283e18?q=80&w=1975&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 1",
  },
  {
    src: "https://images.unsplash.com/photo-1507608616759-54f48f0af0ee?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 2",
  },
  {
    src: "https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 3",
  },
  {
    src: "https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 4",
  },
  {
    src: "https://images.unsplash.com/photo-1500530855697-b586d89ba3ee?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 5",
  },
  {
    src: "https://images.unsplash.com/photo-1533105079780-92b9be482077?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 6",
  },
  {
    src: "https://images.unsplash.com/photo-1440778303588-435521a205bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 7",
  },
  {
    src: "https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 8",
  },
  {
    src: "https://images.unsplash.com/photo-1553342385-111fd6bc6ab3?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 9",
  },
  {
    src: "https://images.unsplash.com/photo-1465188035480-cf3a60801ea5?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 10",
  },
  {
    src: "https://images.unsplash.com/photo-1527610276295-f4c1b38decc5?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 11",
  },
  {
    src: "https://images.unsplash.com/photo-1556276797-5086e6b45ff9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 12",
  },
  {
    src: "https://images.unsplash.com/photo-1467269204594-9661b134dd2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 13",
  },
  {
    src: "https://images.unsplash.com/photo-1467269204594-9661b134dd2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 14",
  },
  {
    src: "https://plus.unsplash.com/premium_photo-1727193170694-4a759aa117dd?q=80&w=1970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 15",
  },
  {
    src: "https://images.unsplash.com/photo-1514896856000-91cb6de818e0?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 16",
  },
  {
    src: "https://images.unsplash.com/photo-1554357345-187a92a9eeab?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 17",
  },
  {
    src: "https://images.unsplash.com/photo-1503177119275-0aa32b3a9368?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 18",
  },
  {
    src: "https://images.unsplash.com/photo-1519451241324-20b4ea2c4220?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 19",
  },
  {
    src: "https://images.unsplash.com/photo-1511135570219-bbad9a02f103?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 20",
  },
  {
    src: "https://images.unsplash.com/photo-1415226556993-1404e0c6e727?q=80&w=1959&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 21",
  },
  {
    src: "https://plus.unsplash.com/premium_photo-1727193170814-415fac3f3ca4?q=80&w=1970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 22",
  },
  {
    src: "https://images.unsplash.com/photo-1489493585363-d69421e0edd3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 23",
  },
  {
    src: "https://images.unsplash.com/photo-1523980077198-60824a7b2148?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 24",
  },
  {
    src: "https://images.unsplash.com/photo-1552301726-570d51466ae2?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 25",
  },
  {
    src: "https://images.unsplash.com/photo-1561969310-fa2e856250ba?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Image 26",
  },
];

export const caseStudies = [
  {
    id: "1",
    // location: "Copenhagen",
    title: "Sparkling Treasures",
    // details:
    //   "<div><strong>Who</strong><br>Global Fashion Agenda, a non-profit thought leadership and advocacy organisation on fashion sustainability.<br><br><strong>What</strong><br>RASK has been involved with the Summit since 2013 and became the official hospitality partner in 2018. We deal with all elements of VIP travel from budgeting to coordinating flights, hotels, and travel logistics for over 200 guests, before and during the three-day annual event. This also includes ground transport for 400 guests during the event itself.</div>",
    images: [
      {
        img: waikato,
        imgName: "Waikato, New Zealand",
      },
      {
        img: fukuoka,
        imgName: "Fukuoka, Japan",
      },
      {
        img: abudhabi,
        imgName: "Abu Dhabi, UAE",
      },
      {
        img: krabi,
        imgName: "Krabi, Thailand",
      },
    ],
  },
  {
    id: "2",
    // location: "Doha",
    title: "Most Enjoyable Evenings",
    // details:
    //   "<div><strong>Who</strong><br>An international organisation at the forefront of research, analysis and dialogue related to global security issues.<br><br><strong>What</strong><br>RASK was contracted to manage travel for over 100 VIPs travelling to Doha for this two-day event. Personalized travel solutions were provided to ambassadors, military officials, CEOs, and more. Our services on the ground during the event ensured everything ran smoothly for the client and that each individual traveller's needs were met seamlessly.</div>",
    images: [
      {
        img: azurmendi,
        imgName: "Azurmendi, Larrabetzu, Spain",
      },
      {
        img: lebernardi,
        imgName: "Le Bernardin, New York, USA",
      },
      {
        img: Robuchon,
        imgName: "Robuchon au Dôme, Macau, China",
      },
    ],
  },
  {
    id: "3",
    // location: "Conrad Maldives",
    title: "Popular Destinations",
    // details:
    //   "<div><strong>Who</strong><br>b:hip Global, an international company helping people be the best versions of themselves, within a global community.<br><br><strong>What</strong><br>RASK organized a unique incentive trip for b:hip Global, coordinating travel for over 150 of their members to the stunning Conrad Maldives. Services included personalized itineraries, on-the-ground logistics, and exclusive experiences designed to reward high-performing individuals within the company.</div>",
    images: [
      {
        img: athens,
        imgName: "Athens Riveria, Greece",
      },
      {
        img: Kyoto,
        imgName: "Kyoto, Japan",
      },
      {
        img: Botswana,
        imgName: "Botswana & Namibia",
      },
    ],
  },
  {
    id: "4",
    // location: "Jakarta",
    title: "One-of-a-Kind Getaway!",
    // details:
    //   "<div><strong>Who</strong><br>FiveCurrents, a premier creator and producer of live content and ceremonies.<br><br><strong>What</strong><br>Following RASK’s successful partnership with FiveCurrents during the 2015 European Games, we were brought back for a second collaboration on an event of even bigger scale. Our team worked 24/7 handling hundreds of flight tickets, dealing with last minutes requests and multiple changes as required by the team. Additionally, we contracted long and short stay rates at several 5* and 4* hotels in town on behalf of our client, before, during and after the event.</div>",
    images: [
      {
        img: burjAiArab,
        imgName: "Burj Al Arab, Dubai, UAE",
      },
      {
        img: neckerIsland,
        imgName: "Necker Island, British Virgin Islands",
      },
      {
        img: Explora,
        imgName: "Explora El Chaltén, Patagonia, Argentina",
      },
    ],
  },
  {
    id: "5",
    // location: "Paris",
    title: "Animal Love",
    // details:
    //   "<div><strong>Who</strong><br>Galerie Mikael Andersen, Denmark’s most respected art gallery, exhibiting Danish and International contemporary art.<br><br><strong>What</strong><br>Presented at the Musée National d'Art Moderne and attended by royalty and members of high society alike, the logistics involved in this ultra-exclusive vernissage needed to be handled meticulously, with the utmost care and attention to detail. Furthermore, choosing the right hotel for the VIPs in attendance was critical and this is where RASK’s knowledge of and partnership with luxury brands was invaluable to the client.</div>",
    images: [
      {
        img: BalletOf,
        imgName: "Ballet of Japanese cranes Hokkaido, Japan",
      },
      {
        img: Penguin,
        imgName: "Penguin Parade Phillip Island, Australia",
      },
      {
        img: blackSun,
        imgName: "“Black Sun” starlings’ Wadden Sea, Denmark",
      },
    ],
  },
  {
    id: "6",
    // location: "Milan",
    title: "Love for Nature",
    // details:
    //   "<div><strong>Who</strong><br>Eco-Age x Camera Nazionale della Moda Italiana.<br><br><strong>What</strong><br>An event that once per year brings together some of the biggest names in media, fashion and design, to promote and celebrate sustainable fashion. Naturally there is a high number of VIPs attending the event, most of whom are handled by RASK. We ensure they travel as comfortably as possible, keeping within the budget and sustainability guidelines, and handle their airport transfers, VIP assistance, and any other special requirements.</div>",
    images: [
      {
        img: biomul,
        imgName: "Bioluminescent plankton Vero Beach, Florida",
      },
      {
        img: international,
        imgName: "International Dark Sky Park Arches National Park, Utah",
      },
      {
        img: starling,
        imgName: "Starling murmurations Somerset, U.K.",
      },
    ],
  },
];

export const termsAndCondition = [
  {
    id: "1",
    heading: "CONTRACT",
    details:
      "<div>Our bookings are tailored to your needs and are subject to availability.<br/></br> A binding contract is formed when we issue a booking confirmation after receiving your payment.</div>",
  },
  {
    id: "2",
    heading: "PRICE AND PAYMENT",
    details:
      "<div>Prices will be confirmed before booking. Payments can be made in AED, USD, or other currencies. <br/><br/> A deposit may be required. Full payment is due 12 weeks before departure. If booking within 12 weeks of departure, full payment is required at booking.</div>",
  },
  {
    id: "3",
    heading: "INSURANCE",
    details:
      "<div>You must have comprehensive travel insurance covering cancellation, health, and other travel-related issues.</div>",
  },
  {
    id: "4",
    heading: "PASSPORTS, VISAS, AND HEALTH FORMALITIES",
    details:
      "<div>Ensure your passport is valid for at least 6 months after your return. Obtain necessary visas and comply with health requirements.</div>",
  },
  {
    id: "5",
    heading: "INFORMATION",
    details:
      "<div>We strive to provide accurate information but reserve the right to make changes.</div>",
  },
  {
    id: "6",
    heading: "AMENDMENT, TRANSFERS, AND CANCELLATION BY YOU",
    details:
      "<div>Requests for changes or cancellations must be in writing. Amendments may incur a fee. Cancellation charges depend on the notice period.</div>",
  },
  {
    id: "7",
    heading: "AMENDMENTS AND CANCELLATION BY US",
    details:
      "<div>We reserve the right to make changes or cancel bookings if necessary.</div>",
  },
  {
    id: "8",
    heading: "PERFORMANCE OF PACKAGE",
    details:
      "<div>We aim to deliver the package as described but may make changes if required.</div>",
  },
  {
    id: "9",
    heading:
      "OUR LIABILITY TO YOU: PRICE REDUCTION AND COMPENSATION FOR DAMAGES",
    details:
      "<div>We are liable for any issues caused by our negligence.</div>",
  },
  {
    id: "10",
    heading: "ASSISTANCE",
    details: "<div>We provide assistance during your trip.</div>",
  },
  {
    id: "11",
    heading: "FLIGHTS",
    details:
      "<div>Flight details will be provided with your booking confirmation.</div>",
  },
  {
    id: "12",
    heading: "IF YOU HAVE A COMPLAINT",
    details:
      "<div>Contact us immediately if you have any issues during your trip.</div>",
  },
  {
    id: "13",
    heading: "SPECIAL REQUESTS",
    details:
      "<div>We will try to accommodate special requests but cannot guarantee them.</div>",
  },
  {
    id: "14",
    heading: "EXCURSIONS AND ACTIVITIES",
    details:
      "<div>Excursions and activities are subject to availability and may change.</div>",
  },
  {
    id: "15",
    heading: "DATA PROTECTION",
    details:
      "<div>We protect your personal data in accordance with our Privacy Policy.</div>",
  },
  {
    id: "16",
    heading: "JURISDICTION",
    details:
      "<div>These terms are governed by the laws of the United Arab Emirates.</div>",
  },
  {
    id: "17",
    heading: "DOCUMENTATION",
    details: "<div>Ensure you have all necessary travel documents.</div>",
  },
  {
    id: "18",
    heading: "CONTACT US",
    details:
      "<div>For any inquiries, contact us at <a href='/'><strong className='underline hover:no-underline'>www.iskytravel.com</strong></a></div>",
  },
];
